var profileDataValue={};

function onProfileData(profileData){
  localStorage.setItem("profileData",JSON.stringify(profileData));
  if(profileData) {
    var jobId = document.querySelectorAll("[data-company-job-code]")[0] ? document.querySelectorAll("[data-company-job-code]")[0].dataset.companyJobCode:'';
    if(jobId && window.dataLayer) {
      window.dataLayer.push({
        'event':'Linkedin Job Apply',
        'ca1':'Application Method',
        'ca2':'LinkedInApply',
        'ca3':jobId,
        'applyMethod':'LinkedInApply'
        });
    }
    location.reload();
  }
}

function htmlDecode(input){
  var e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

function stripScripts(s) {
  var div = document.createElement('div');
  div.innerHTML = s;
  var scripts = div.getElementsByTagName('script');
  var i = scripts.length;
  while (i--) {
    scripts[i].parentNode.removeChild(scripts[i]);
  }
  return div.innerHTML;
}
